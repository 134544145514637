import { Controller } from 'stimulus'
import $ from 'jquery';

export default class extends Controller {
  static targets = ['tagsField']

  connect() {
    let legislatorTagsController = this

    $(this.tagsFieldTarget).select2({
      theme: 'bootstrap4'
    }).on('select2:open', function() {
      legislatorTagsController.addNewTagOption()
    })

    $(document).on('click', '.add-new-tag', function(e) {
      legislatorTagsController.createNewFieldInput(e)
    })

    $(document).on('keyup', 'input.new-tag-input', function(e) {
      legislatorTagsController.addNewTag(e)
    })
  }

  addNewTagOption() {
    $('.select2-results:not(:has(a))').append('<a class="add-new-tag" href="#" style="padding: 6px;height: 20px;display: inline-table;">+ Add Tag</a>')
  }

  createNewFieldInput(e) {
    e.preventDefault()
    $('.add-new-tag').hide()

    $('.select2-results').append('<span class="select2-search select2-search--dropdown"><input class="select2-search__field new-tag-input" type="search" tabindex="0" autocomplete="off" autocorrect="off" autocapitalize="none" spellcheck="false" role="textbox"></span>')
  }

  addNewTag(e) {
    if (e.keyCode === 13) {
      let newValue = $('.new-tag-input').val()
      let newOpt = $('<option>').val(newValue).text(newValue)
      let data = []

      $(this.tagsFieldTarget).select2('data').forEach((i) => { data.push(i.text) })
      
      // Add the option to the list if it doesn't exist
      if (!$("#" + this.tagsFieldTarget.id + " option[value='" + newValue + "']").length > 0)
        $(this.tagsFieldTarget).append(newOpt)

      data.push(newValue)
      
      $(this.tagsFieldTarget).select2({theme: 'bootstrap4'}).select2('close').val(data).trigger('change')
    }
  }
}