import flatpickr from "flatpickr"

export default {
  renderContent(selector, content) {
    document.querySelector(selector).innerHTML = content
  },

  removeElement(selector) {
    document.querySelector(selector).remove()
  },

  hideContent(selector) {
    document.querySelector(selector).style.display = 'none';
  },

  showModal(title, content) {
    this.renderContent("#main-modal .modal-title", title)
    this.renderContent("#main-modal .modal-content-holder", content)

    $('#main-modal').modal({ backdrop: 'static' })
  },
  
  hideModal() {
    $('#main-modal').modal('hide')
  },

  createDatePicker() {
    flatpickr(".date-picker", {
      altInput: true,
      altFormat: 'm/d/Y',
      static: true
    });
  },

  createRangePicker() {
    flatpickr(".range-picker", {
      mode: 'range',
      altInput: true,
      altFormat: 'm/d/Y'
    });
  },

  createTimePicker() {
    flatpickr(".time-picker", {
      enableTime: true,
      noCalendar: true,
      dateFormat: "H:i",
      altInput: true,
      altFormat: 'G:i K',
      static: true
    });
  },

  forEach(array, callback, scope) {
    for (var i = 0; i < array.length; i++) {
      callback.call(scope, i, array[i])
    }
  },
  
  trackGoogleEvent(eventName, eventCategory, eventLabel, eventValue) {
    gtag('event', eventName, {
      'event_category': eventCategory,
      'event_label': eventLabel,
      'event_value': eventValue
    })
  }
}