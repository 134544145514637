import { Controller } from 'stimulus'
import $ from 'jquery';
import 'jquery-ui/ui/widgets/autocomplete';

export default class extends Controller {
  connect() {
    let legislatorsFiltersController = this
    
    this.populateCommittees()
    this.populateDistricts()
    this.populateTags()
    this.populateSearchAutocomplete()
    this.addCustomSearchHandlers()

    $('#q_legislature_id_eq').on('changed.bs.select', () => {
      legislatorsFiltersController.populateTags()
    })

    // for multiselect filters, submit form on hide dropdown event
    $('#q_party_id_in, #q_support_level_in, #q_status_eq, #q_committees_id_in, #q_district_id_in, #q_tags_name_in')
      .on('hide.bs.select', () => {
        $('form#legislator_search').submit()
      })
      .on('changed.bs.select', (e, clickedIndex, isSelected, previousValue) => {
        if (isSelected) {
          e.currentTarget.offsetParent.classList.add('selected')
        } else {
          e.currentTarget.offsetParent.classList.remove('selected')
        }
      })
      .on('loaded.bs.select', (e, index, isSelected) => {
        if (e.currentTarget.value) {
          e.currentTarget.parentElement.classList.add('selected')
        } else {
          e.currentTarget.parentElement.classList.remove('selected')
        }
      })
  }

  addCustomSearchHandlers() {
    $('#legislator-name-search').keypress(e => {
      if (e.which == 13) {
        $('form#legislator_search').submit()
        return false
      }
    });
  }

  populateSearchAutocomplete() {
    let chamber = $('#q_chamber_type_eq').val()
    let legislature = $('#q_legislature_id_eq').val()

    if (chamber) {
      this.getResource(`/legislators/full_names?legislature_id=${legislature}&chamber_type=${chamber}`)
        .then((json) => {

          const legislatorNames = JSON.parse(json)
          const legislatorSearchBar = $("#legislator-name-search").first()
          legislatorSearchBar.autocomplete({
            source: legislatorNames,
            select: () => {
              setTimeout(() => $('form#legislator_search').submit(), 0)
            }
          })

        })
    }
  }

  populateTags() {
    let legislature = $('#q_legislature_id_eq').val()

    if (legislature) {
      this.getResource(`/legislators/tags.for_select?legislature_id=${legislature}`)
        .then((html) => {
          $('#q_tags_name_in').html(html)
          
          $('#q_tags_name_in').selectpicker('refresh')

          let selectedValue = $("#q_tags_name_in").data('selected-id')
          if (selectedValue) {
            $('#q_tags_name_in').selectpicker('val', selectedValue)
          }
        })
    }
  }

  populateDistricts() {
    let chamber = $('#q_chamber_type_eq').val()
    let legislature = $("#q_legislature_id_eq").val()

    if (chamber) {
      this.getResource(`/legislators/districts.for_select?legislature_id=${legislature}&chamber_type=${chamber}`)
        .then((html) => {
          $('#q_district_id_in').html(html)
          $('#q_district_id_in').selectpicker('refresh')

          let selectedValue = $("#q_district_id_in").data('selected-id')
          if (selectedValue) {
            $('#q_district_id_in').selectpicker('val', selectedValue)
          }
        })
    }
  }

  populateCommittees() {
    let chamber = $('#q_chamber_type_eq').val()
    let legislature = $("#q_legislature_id_eq").val()

    if (chamber) {
      this.getResource(`/legislators/committees.for_select?legislature_id=${legislature}&chamber_type=${chamber}`)
        .then((html) => {
          $('#q_committees_id_in').html(html)
          $('#q_committees_id_in').selectpicker('refresh')

          let selectedValue = $("#q_committees_id_in").data('selected-id')
          if (selectedValue) {
            $('#q_committees_id_in').selectpicker('val', selectedValue)
          }
        })
    }
  }

  getResource(url) {
    return new Promise(function(resolve, reject) {
      fetch(url)
        .then(response => response.text())
        .then(html => {
          resolve(html)
        })
    });
  }
}