import { Controller } from "stimulus"
import $ from 'jquery';

export default class extends Controller {
  static targets = ['validationController', 'submitButton', 'legislaturesField', 'chambersField', 'districtsField', 'committeesField', 'tagsField']

  connect() {
    this.checkForm()
  }

  checkForm() {
    if (this.legislaturesFieldTarget.value) {
      this.populateChambers()
    }
  }

  populateChambers() {
    let chambersUrl = this.legislaturesFieldTarget.getAttribute('data-chambers-url')
    let value = this.legislaturesFieldTarget.options[this.legislaturesFieldTarget.selectedIndex].value

    if (value) {
      this.getResource(`${chambersUrl}?legislature_id=${value}`, this.chambersFieldTarget)
        .then(() => {
          if (this.chambersFieldTarget.getAttribute('data-id'))
            this.chambersFieldTarget.value = this.chambersFieldTarget.getAttribute('data-id')
        })
        .then(() => {
          this.populateDistrictsAndCommittees()
        });
    } else {
      this.chambersFieldTarget.innerHTML = "";
      this.districtsFieldTarget.innerHTML = "";
      this.committeesFieldTarget.innerHTML = "";

      let submitEvent = new SubmitEvent('submit', { submitter: this.submitButtonTarget })
      this.application.getControllerForElementAndIdentifier(this.validationControllerTarget, 'validation').validateAll(submitEvent)
    }
  }

  populateTags() {
    let tagsUrl = this.legislaturesFieldTarget.getAttribute('data-tags-url')
    let value = this.legislaturesFieldTarget.options[this.legislaturesFieldTarget.selectedIndex].value

    if (value) {
      this.getResource(`${tagsUrl}?legislature_id=${value}`, this.tagsFieldTarget)
    }
  }

  populateDistrictsAndCommittees(event) {
    let districtsUrl = this.chambersFieldTarget.getAttribute('data-districts-url')
    let committeesUrl = this.chambersFieldTarget.getAttribute('data-committees-url')
    let value = this.chambersFieldTarget.options[this.chambersFieldTarget.selectedIndex].value

    if (value) {
    this.getResource(`${districtsUrl}?chamber_id=${value}`, this.districtsFieldTarget)
      .then(() => {
        if (this.districtsFieldTarget.getAttribute('data-id'))
          this.districtsFieldTarget.value = this.districtsFieldTarget.getAttribute('data-id')
      })

    this.getResource(`${committeesUrl}?chamber_id=${value}`, this.committeesFieldTarget)
      .then(() => {
        if (this.committeesFieldTarget.getAttribute('data-ids')) {
          let value = JSON.parse(this.committeesFieldTarget.getAttribute('data-ids'))
          this.committeesFieldTarget.value = value

          // This field uses select2. Trigger the change through JavaScript to set the values
          $('#legislator_committee_ids').val(value)
          $('#legislator_committee_ids').trigger('change')
        }
      })
    }
  }

  getResource(url, target) {
    return new Promise(function(resolve, reject) {
      fetch(url)
        .then(response => response.text())
        .then(html => {
          target.innerHTML = html
          resolve()
        })
    });
  }
}