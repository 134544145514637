// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require('jquery')

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import '../stylesheets/application'

import 'bootstrap'
import 'select2'
import 'bootstrap-select'
import flatpickr from "flatpickr"
import Slider from 'bootstrap-slider'
import App from '../javascripts/app'
import $ from 'jquery'
import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"
import FormValidationController from "../javascripts/controllers/form_validation_controller"

const application = Application.start()
const controllers = require.context("../javascripts/controllers", true, /_controller\.js$/)

application.load(definitionsFromContext(controllers))
application.register("validation", FormValidationController)

global.$ = jQuery
window.App = App

document.addEventListener("turbolinks:load", () => {
  $('[data-toggle="tooltip"]').tooltip()

  $('.select2-dd').select2({
    theme: 'bootstrap4',
  })

  App.createDatePicker()
  App.createRangePicker()

  if (document.getElementsByClassName('slider').length > 0) {
    let slider = new Slider('input.slider', {
      tooltip: 'always',
      min: 1,
      max: 7
    })
  }

  $(document).on('shown.bs.modal', function() {
    App.createDatePicker()
    App.createTimePicker()

    $('.select2-dd').select2({
      theme: 'bootstrap4',
    })
  })

  $('.selectpicker').selectpicker()

  // Add down arrow icon for collapse element which is open by default
  $(".collapse.show").each(function(){
    $(this).prev(".card-header").find(".fa").addClass("fa-angle-up").removeClass("fa-angle-down");
  });
  
  // Toggle right and down arrow icon on show hide of collapse element
  $(".collapse").on('show.bs.collapse', function(){
    $(this).prev(".card-header").find(".fa").removeClass("fa-angle-down").addClass("fa-angle-up");
  }).on('hide.bs.collapse', function(){
    $(this).prev(".card-header").find(".fa").removeClass("fa-angle-up").addClass("fa-angle-down");
  });

  $(document).on('click', '.js-popup', function() {
    centerPopup($(this).attr('href'), $(this).attr('data-width'), $(this).attr('data-height'));
    return false;
  });
})

document.addEventListener("turbolinks:before-cache", () => {
  if ($('.select2').first().data('select2') != undefined) 
    $('.select2').select2('destroy');

  $('.selectpicker').selectpicker('destroy').addClass('selectpicker')
})

function centerPopup(linkUrl, width, height) {
  var sep = (linkUrl.indexOf('?') !== -1) ? '&' : '?',
    url = linkUrl + sep + 'popup=true',
    left = (screen.width - width) / 2 - 16,
    top = (screen.height - height) / 2 - 50,
    windowFeatures = 'menubar=no,toolbar=no,status=no,width=' + width +
      ',height=' + height + ',left=' + left + ',top=' + top;

    return window.open(url, 'authPopup', windowFeatures);
}
