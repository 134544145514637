import { Controller } from 'stimulus'
import { Calendar } from '@fullcalendar/core'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'
import $ from 'jquery';

export default class extends Controller {
  static targets = ['calendarContainer', 'cardContainer']

  connect() {
    let calendar = new Calendar(this.calendarContainerTarget, {
      themeSystem: 'bootstrap',
      plugins: [ dayGridPlugin, timeGridPlugin, listPlugin ],
      headerToolbar: {
        start: 'today',
        center: 'prev,title,next', 
        end: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek' 
      },
      eventSources: [
        {
          id: 'default',
          url: '/legislator_meetings.json',
          method: 'GET',
        }
      ],
      eventClick: this.eventClicked,
      listDayFormat: {
        month: 'long',
        year: 'numeric',
        day: 'numeric',
        weekday: 'long'
      },
      listDaySideFormat: false
    });
    
    calendar.render()
    window.fullCalendar = calendar

    $(document).on('click', 'body', function (e) {
      if (window.popoverElement && ((!window.popoverElement.is(e.target) && window.popoverElement.has(e.target).length === 0 && $('.popover').has(e.target).length === 0) || (window.popoverElement.has(e.target) && e.target.id === 'closepopover'))) {

        ///$('.popover').popover('hide'); --> works
        $('.popover').not(this).popover('hide')
      }
    })

    $(document).on('changed.bs.select', '#state-picker', function(event, clickedIndex, isSelected, previousValue) {
      window.fullCalendar.getEventSourceById('default').remove()
      
      window.fullCalendar.addEventSource({
        id: 'default',
        url: '/legislator_meetings.json',
        method: 'GET',
        extraParams: {
          state_id: $('#state-picker').val()
        }
      })

      //window.fullCalendar.refetchEvents()
    })
  }

  disconnect() {
    window.popoverElement = null
    window.fullCalendar = null
  }

  eventClicked(info) {
    info.jsEvent.preventDefault() // don't let the browser navigate
    $('.popover').not(this).popover('hide')
    window.popoverElement = $(info.jsEvent.target)

    let popoverTemplate = '<div class="legislator-meeting-popover popover" role="tooltip"><div class="arrow"></div><h3 class="popover-header"></h3><div class="popover-body"></div></div>'

    if (info.event.url) {
      fetch(info.event.url)
        .then(response => response.text())
        .then(html => {
          $(info.jsEvent.target).popover({
            content: html,
            sanitize: false,
            template: popoverTemplate,
            placement: 'top',
            html: true,
            animation: true,
            trigger: 'click',
            container: 'body'
          }).popover('show')
        })
    }
  }
}