import { Controller } from 'stimulus'
import $ from 'jquery'

export default class extends Controller {
  connect() {
    $("#q_type_in")
      .on('hide.bs.select', () => {
        $('form#legislator_contact_log_search').submit()
      })
      .on('changed.bs.select', (e, clickedIndex, isSelected, previousValue) => {
        if (isSelected) {
          e.currentTarget.offsetParent.classList.add('selected')
        } else {
          e.currentTarget.offsetParent.classList.remove('selected')
        }
      })
      .on('loaded.bs.select', (e, index, isSelected) => {
        if (e.currentTarget.value) {
          e.currentTarget.parentElement.classList.add('selected')
        } else {
          e.currentTarget.parentElement.classList.remove('selected')
        }
      })

    flatpickr(".custom-range-picker", {
      mode: 'range',
      altInput: true,
      altFormat: 'm/d/Y',
      onClose: () => {
        console.log('here')
        $('form#legislator_contact_log_search').submit()
      }
    });
  }
}