import { Controller } from "stimulus"

export default class extends Controller {
  populateSelect(event) {
    let url = event.target.getAttribute('data-url')
    let target = event.target.getAttribute('data-target')
    let param = event.target.getAttribute('data-param')
    let value = event.target.options[event.target.selectedIndex].value

    fetch(`${url}?${param}=${value}`)
      .then(response => response.text())
      .then(html => {
        document.getElementById(target).innerHTML = html
      })
  }
}