export default class {
  constructor(el, errorMessage, config) {
    this.el = el
    this.errorMessage = errorMessage
    this.config = config
  }

  render() {
    if (!this.container) return
    this._insertErrorMessageElement()

    if (this.errorMessage) {
      this.errorElement.textContent = this.errorMessage
      this.container.classList.add(this.config.containerErrorClass)
      this.el.classList.remove(this.config.fieldValidClass)
      this.el.classList.add(this.config.fieldErrorClass)
      this.errorElement.style.display = "block"
    } else {
      this.container.classList.remove(this.config.containerErrorClass)
      this.el.classList.remove(this.config.fieldErrorClass)
      this.el.classList.add(this.config.fieldValidClass)
      this.errorElement.textContent = ""
      this.errorElement.style.display = "none"
    }
  }

  _insertErrorMessageElement() {
    if (!this.inputErrorContainer.querySelector("." + this.config.errorMessageClass)) {
      this.inputErrorContainer.insertAdjacentHTML(
        this.errorMessagePosition,
        `<div class="${this.config.errorMessageClass}"></div>`
      )
    }
  }

  get errorElement() {
    //return this.container.querySelector("." + this.config.errorMessageClass)
    return this.el.closest('.form-group').querySelector("." + this.config.errorMessageClass)
  }

  get container() {
    return this.el.closest(this.config.containerSelector)
  }

  get inputErrorContainer() {
    return this.el.closest('.form-group').querySelector("." + this.config.inputErrorContainerSelector)
  }

  get errorMessagePosition() {
    switch (this.config.errorMessagePosition) {
      case "end":
        return "beforeend"
      case "start":
        return "afterbegin"

      default:
        return "beforeend"
    }
  }
}
