import { Controller } from "stimulus"
import 'chart.js'

export default class extends Controller {
  static targets = ["chartLocation"]

  connect() {
    let ctx = this.chartLocationTarget.getContext('2d')
    let colors = {
      '1 - Champion': "#007E33",
      '2 - Confirmed Supportive': "#00C851",
      '3 - Leans Supportive': "#c6ff00",
      '4 - Unknown': "#ffbb33",
      '5 - Leans Opposed': "#ff6f00",
      '6 - Confirmed Opposed': "#ff4444",
      '7 - Active Opponent': "#CC0000",
      '8 - No Contact': "#CCC"
    }

    let backgroundColors = {}
    let data = JSON.parse(this.data.get('data'))

    Object.keys(data).forEach((item) => {
      backgroundColors[item] = colors[item]
    })

    let voteCountMeter = new Chart(ctx, {
      type: 'doughnut',
      data: {
        datasets: [{
          data: Object.values(data),
          backgroundColor: Object.values(backgroundColors)
        }],
        // These labels appear in the legend and in the tooltips when hovering different arcs
        labels: Object.keys(data)
      },
      // Didn't realize I had to use math here :(
      options: {
        circumference: Math.PI,
        rotation: -Math.PI,
        legend: {
          display: false
        }
      }
    })
  }
}